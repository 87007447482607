import React, { useEffect, useRef, useState } from 'react';
import ImageView from '../components/imageView';
import VideoJS from '../components/videoJS';

const MainPage: React.FC = () => {
  const playerRef = React.useRef<any>(null);
  const playerContainerRef = React.useRef<any>(null);
  const secondPlayerRef = React.useRef<any>(null);
  const secondPlayerContainerRef = React.useRef<any>(null);

  const videoJsOptions = {
    autoplay: true,
    controls: false,
    responsive: true,
    muted: true,
    fluid: true,
    aspectRatio: '1:1',
    fill: true,
    preload: 'auto',
    loadingSpinner: false,
    buffered: [0, 5],
    playsinline: true
  };

  const currentIndexRef = useRef<number>(0);

  const [playlist, setPlaylist] = useState<Array<any>>([]);

  const getNextAd = () => {
    return (currentIndexRef.current + 1) % playlist.length;
  }

  const sendMessageToWebview = (payload: object) => {
    //@ts-ignore
    window?.ReactNativeWebView?.postMessage(JSON.stringify(payload));
  }

  const handlePlayerReady = (player: any) => {
    playerRef.current = player;

    playerRef.current.load();
    playerRef.current.on('loadeddata', () => playerRef.current.play())
  }

  const handlePlayerEnded = () => {

    sendMessageToWebview({
      view: playerRef.current.getAdvertisementCurrent(),
    });

    if (playlist.length === 1) {
      playerRef.current.play();
      return;
    }

    playerRef.current.hidden();

    currentIndexRef.current = getNextAd();
    secondPlayerRef.current.play();

    secondPlayerRef.current.show();
    const nextIndex = getNextAd();

    playerRef.current.src(playlist[nextIndex]);
    playerRef.current.on('loadeddata', () => {
      playerRef.current.pause();
    });
  }

  const handleSecondPlayerReady = (player: any) => {
    secondPlayerRef.current = player;
  }

  const handleSecondPlayerEnded = () => {

    sendMessageToWebview({
      view: secondPlayerRef.current.getAdvertisementCurrent(),
    });

    secondPlayerRef.current.hidden();

    currentIndexRef.current = getNextAd();
    playerRef.current.play();

    playerRef.current.show();

    const nextIndex = getNextAd();
    secondPlayerRef.current.src(playlist[nextIndex]);

    secondPlayerRef.current.on('loadeddata', () => {
      secondPlayerRef.current.pause();
    });
  }

  useEffect(() => {
    const onMessage = (event: any) => {
      if (event && event.data && typeof event.data === 'string') {
        const payloadEvent = JSON.parse(event.data);

        if (payloadEvent.type === "app-mobile") {
          const payload = payloadEvent.data;
          if (payload.status) {
            if (payload.status === "pause") {
              playerRef.current.pause();
              secondPlayerRef.current.pause();
            }
            if (payload.status === "play") {
              if (playerRef.current.isVisible()) {
                playerRef.current.play();
              }
              if (secondPlayerRef.current.isVisible()) {
                secondPlayerRef.current.play();
              }
            }
          }

          if (payload.playlist) {
            setPlaylist(payload.playlist);
          }
        }
      }
    };

    window?.addEventListener('message', onMessage);
    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  const AdsPlayer = ({ containerRef, initialSource, onReady, onEnded }: any) => {
    const [source] = useState(initialSource);
    const playerImageRef = useRef<any>(null);
    const advertisement = useRef(initialSource);

    const handleRef = (player: any) => {
      return {
        src: (source: any) => {
          advertisement.current = (source);
          if (source && source.type.startsWith('image/')) {
            playerImageRef.current.show();
            playerImageRef.current.src(source);
          } else {
            player.src(source);
            playerImageRef.current.hidden();
          }
        },
        getAdvertisementCurrent: () => {
          return advertisement.current;
        },
        show: () => {
          containerRef.current.style.display = 'block';
        },
        hidden: () => {
          containerRef.current.style.display = 'none';
        },
        isVisible: () => {
          return containerRef.current.style.display === 'block';
        },
        play: () => {
          if (!playerImageRef.current.getStatus().visible) {
            player.play();
          } else {
            playerImageRef.current.play(5);
          }
        },
        pause: () => {
          if (!playerImageRef.current.getStatus().visible) {
            player.pause();
          } else {
            playerImageRef.current.pause(5);
          }
        },
        load: () => player.load(),
        on: (name: string, callback: any) => {
          if (!playerImageRef.current.getStatus().visible) {
            player.on(name, callback);
          } else {
            playerImageRef.current.on(name, callback);
          }
        },
      };
    }

    return (
      <div style={{ aspectRatio: 1 }} ref={containerRef}>
        <ImageView
          refFunction={(ref: any) => playerImageRef.current = ref}
          source={source}
          duration={8}
          onEnded={onEnded}
        />
        <VideoJS
          options={videoJsOptions}
          source={source}
          playsInline
          onReady={(player: any) => {
            onReady(handleRef(player));
          }}
          onEnded={onEnded}
        />
      </div>
    );
  }

  return (
    <div className="App">
      <div className="PlayerContainer">
        {
          playlist.length > 0 ?
            <>
              <AdsPlayer
                containerRef={playerContainerRef}
                initialSource={playlist[0]}
                onReady={handlePlayerReady}
                onEnded={handlePlayerEnded}
              />
              {
                playlist.length > 1 &&
                <AdsPlayer
                  containerRef={secondPlayerContainerRef}
                  initialSource={playlist[1]}
                  onReady={handleSecondPlayerReady}
                  onEnded={handleSecondPlayerEnded}
                />
              }
            </>
            :
            <></>
        }
      </div>
    </div>
  );
}

export default MainPage;