import React from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

export const VideoJS = (props: any) => {
  const videoRef = React.useRef<any>(null);
  const playerRef = React.useRef<any>(null);
  const { options, onReady, source, onEnded } = props;

  React.useEffect(() => {

    // Make sure Video.js player is only initialized once
    if (!playerRef.current) {
      // The Video.js player needs to be _inside_ the component el for React 18 Strict Mode. 
      const videoElement = document.createElement("video-js");

      videoElement.classList.add('vjs-big-play-centered');
      videoRef.current?.appendChild(videoElement);

      const player = playerRef.current = videojs(videoElement, { ...options, paused: false, sources: source || options.sources }, () => {
        videojs.log('player is ready');
        onReady && onReady(player);
      });

      if (onEnded) {
        player.on('ended', onEnded);
      }
      // You could update an existing player in the `else` block here
      // on prop change, for example:
    } else {
      const player = playerRef.current;

      // player.autoplay(paused ? false : options.autoplay);
      player.src(source || options.sources);
    }
  }, [options, videoRef, onReady, onEnded, source]);

  // Dispose the Video.js player when the functional component unmounts
  React.useEffect(() => {
    const player = playerRef.current;

    return () => {
      if (player && !player.isDisposed()) {
        player.dispose();
        playerRef.current = null;
      }
    };
  }, [playerRef]);

  return (
    <div style={{ visibility: 'visible' }} data-vjs-player>
      <div ref={videoRef} />
    </div>
  );
}

export default VideoJS;