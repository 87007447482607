import { getAnalytics, logEvent } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import React from 'react';
import ReactDOM from 'react-dom/client';
import 'video.js/dist/video-js.css';
import App from './App';
import './index.css';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfigProd = {
  apiKey: "AIzaSyA4oXBZVtZ4mKl-J6zc5IXvnR2KKaOgN84",
  authDomain: "pontuax-ads-player.firebaseapp.com",
  projectId: "pontuax-ads-player",
  storageBucket: "pontuax-ads-player.appspot.com",
  messagingSenderId: "404022144144",
  appId: "1:404022144144:web:a4bbc464ef95d6eb8f7c15",
  measurementId: "G-VEW4Q1E4XE"
};

const firebaseConfigDev = {
  apiKey: "AIzaSyC4SsKQ6frLTJbJ-UmIZEhVB4AR4keux7g",
  authDomain: "pontuax-ads-player-dev.firebaseapp.com",
  projectId: "pontuax-ads-player-dev",
  storageBucket: "pontuax-ads-player-dev.appspot.com",
  messagingSenderId: "296132983197",
  appId: "1:296132983197:web:5dd84a78b1e56694fa5774",
  measurementId: "G-VMT4Q0YGSS"
};
console.log("🚀 ~ process.env.NODE_ENV:", process.env.NODE_ENV)

// Initialize Firebase
const app = initializeApp(process.env.NODE_ENV === 'production' ? firebaseConfigProd : firebaseConfigDev);
const analytics = getAnalytics(app);
logEvent(analytics, 'app_started');