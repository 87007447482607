import React, { useEffect, useRef, useState } from 'react';

interface ImageViewProps {
  refFunction?: any,
  source?: any,
  duration: number,
  onReady?: () => void,
  onEnded: () => void,
}

const ImageView: React.FC<ImageViewProps> = ({ refFunction, source, onEnded }) => {

  const [sourceImg, setSourceImg] = useState(source);
  const imageRef = useRef<any>(null);


  useEffect(() => {
    if (source.type.startsWith('image/')) {
      imageRef.current.style.display = 'flex';
    }

    let timer: NodeJS.Timeout;

    refFunction({
      src: (src: object) => {
        setSourceImg(src);
      },
      show: () => {
        if (imageRef?.current) {
          imageRef.current.style.display = 'flex';
        }
      },
      hidden: () => {
        if (imageRef?.current) {
          imageRef.current.style.display = 'none';
        }
      },
      getStatus: () => {
        return {
          visible: imageRef.current.style.display === 'flex',
        };
      },
      pause: () => {
        clearTimeout(timer);
      },
      play: () => {
        imageRef.current.src = sourceImg.src;
        timer = setTimeout(() => {
          onEnded();
        }, sourceImg.duration * 1000);
      },
      on: (listener: string, callback: () => void) => {
        callback();
      }
    })
  }, [refFunction, setSourceImg, imageRef, sourceImg, onEnded, source]);

  return (
    <>
      <img ref={imageRef} alt="ads" src={sourceImg ? sourceImg.src : null} style={{ display: 'none', height: '100%', aspectRatio: 1, width: 'auto' }} />
    </>
  );
}

export default ImageView;